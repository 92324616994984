<template lang="pug">
.loookbook
  .top
    img.top__leaf1(
      src="~@/assets/jklookbook/leaf1.png",
      alt="JK Shopping",
      draggable="false"
    )
    img.top__leaf2(
      src="~@/assets/jklookbook/leaf2.png",
      alt="JK Shopping",
      draggable="false"
    )
    img.top__leaf3(
      src="~@/assets/jklookbook/leaf3.png",
      alt="JK Shopping",
      draggable="false"
    )
    img.top__jklogo(
      src="~@/assets/jklookbook/logo.png",
      alt="JK Shopping",
      draggable="false"
    )
    img.top__elipse(
      src="~@/assets/jklookbook/elipse.png",
      alt="JK Shopping",
      draggable="false"
    )
    .container
      img.top__woman(
        src="~@/assets/jklookbook/woman.png",
        alt="Lookbook Outono Inverno 2021",
        draggable="false"
      )
      .top__right
        img.top__logo(
          src="~@/assets/jklookbook/lookbook.png",
          alt="Lookbook Outono Inverno 2021",
          draggable="false"
        )
        h2 Conheça as principais tendências do Outono/Inverno.
        p O outono/inverno é conhecido como a estação mais chique do ano. Afinal, as produções para encarar o frio costumam transmitir elegância e sofisticação.
        a(href="#baixar-agora", v-smooth-scroll="{ duration: 1200 }")
          img(src="~@/assets/jklookbook/button.png")
  .photos
    img(src="~@/assets/jklookbook/1.jpg", alt="JK Shopping", draggable="false")
    img(src="~@/assets/jklookbook/2.jpg", alt="JK Shopping", draggable="false")
    img(src="~@/assets/jklookbook/3.jpg", alt="JK Shopping", draggable="false")
    img(src="~@/assets/jklookbook/4.jpg", alt="JK Shopping", draggable="false")
    img(src="~@/assets/jklookbook/5.jpg", alt="JK Shopping", draggable="false")
  .form
    img.form__leaf4(
      src="~@/assets/jklookbook/leaf4.png",
      alt="JK Shopping",
      draggable="false"
    )
    img.form__leaf5(
      src="~@/assets/jklookbook/leaf5.png",
      alt="JK Shopping",
      draggable="false"
    )
    .form__container
      p Com a chegada das baixas temperaturas é hora de dar um up no guarda-roupa e se preparar para a estação mais fria do ano. Se você está sempre antenado neste universo fashion, assim como nós, baixe o&nbsp;
        b lookbook exclusivo do JK Shopping e inspire-se!
      form#baixar-agora(@submit="submitForm")
        input(
          name="name",
          type="text",
          v-model="name",
          placeholder="Meu Nome",
          required
        )
        input(
          name="email",
          type="email",
          v-model="email",
          placeholder="Meu E-mail",
          required
        )
        input(
          name="phone",
          type="phone",
          v-model="phone",
          v-mask="'(##) #####-####'",
          placeholder="Meu Telefone",
          required
        )
        button(type="submit", v-if="!showLoading")
          img(src="~@/assets/jklookbook/button.png")
        .form__success(v-if="showSuccess") Obrigado! O download foi iniciado.
        .form__error(v-if="showError") Ocorreu um erro. Por favor, tente novamente mais tarde.
        img.form__loading(
          v-if="showLoading",
          src="~@/assets/icons/white/loading.svg"
        )
      .form__final Aproveite para visitar o JK Shopping e conhecer de perto as principais tendências da estação.
      .form__social
        a(href="https://www.instagram.com/jkshopping_df" target="_blank")
          img(src="~@/assets/jklookbook/instagram.svg")
        a(href="https://www.youtube.com/channel/UCcJ0mUgidWdofoMnHJWSvMA" target="_blank")
          img(src="~@/assets/jklookbook/youtube.svg")
        a(href="https://www.facebook.com/jkshoppingdf" target="_blank")
          img(src="~@/assets/jklookbook/facebook.svg")
        .form__socialtitle JKSHOPPINGDF
      img.form__logo(src="~@/assets/jklookbook/logowhite.png")
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      showLoading: "",
      showSuccess: "",
      showError: "",
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.showLoading = true;
      this.showSuccess = false;
      this.showError = false;

      const instance = this;

      // const params = {
      //   name: this.name,
      //   email: this.email,
      //   phone: this.phone,
      // };

      var params = new FormData();
      params.append('email', this.email);
      params.append('name', this.name);
      params.append('phone', this.phone);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          "https://outonoinverno.jkshoppingdf.com.br/jk.php",
          params,
          config
        )
        .then(function () {
          // After request and success
          instance.showLoading = false;
          instance.showSuccess = true;
          instance.showError = false;

          let a = document.createElement("a");

          a.href =
            "https://outonoinverno.jkshoppingdf.com.br/Lookbook_JKShopping_Outono_Inverno_2021.pdf";
          a.setAttribute(
            "download",
            "Lookbook_JKShopping_Outono_Inverno_2021.pdf"
          );
          a.click();
        })
        .catch(function () {
          // After request and error
          instance.showLoading = false;
          instance.showSuccess = false;
          instance.showError = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 60px 0px;
  background-color: #f77f34;
  background-image: url("~@/assets/jklookbook/fonts/~@/assets/jklookbook/texture2.png");
  background-size: cover;
  position: relative;
  &__error {
    text-align: center;
    color: #fff;
    margin-top: 8px;
    font-size: 24px;
  }
  &__success {
    text-align: center;
    color: #fff;
    margin-top: 8px;
    font-size: 24px;
  }
  &__loading {
    width: 52px;
    padding: 9px 0px;
    display: block;
    margin: auto;
    margin-top: 48px;
  }
  &__social {
    text-align: center;
    margin: 60px 0px;
    a {
      width: 28px;
      display: inline-block;
      vertical-align: middle;
      margin: 0px 2px;
      img {
        width: 100%;
      }
    }
    &title {
      font-weight: 800;
      color: #ffffff;
      font-size: 22px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 12px;
    }
  }
  &__logo {
    display: block;
    margin: auto;
  }
  &__leaf4 {
    position: absolute;
    bottom: 24px;
    left: 0;
    @media screen and (max-width: 900px) {
      width: 40px;
    }
  }
  &__leaf5 {
    position: absolute;
    bottom: 0px;
    right: 0px;
    @media screen and (max-width: 900px) {
      width: 120px;
    }
  }
  &__final {
    color: #fff;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    line-height: 150%;
    width: 480px;
    max-width: 100%;
    margin: auto;
    display: block;
    @media screen and (max-width: 900px) {
      font-size: 22px;
    }
  }
  &__container {
    width: 600px;
    max-width: 90%;
    display: block;
    margin: auto;
  }
  p {
    color: #ffffff;
    font-size: 26px;
    text-align: center;
    line-height: 34px;
    font-weight: 200;
    b {
      font-weight: 800;
    }
  }

  form {
    display: block;
    margin: 48px 0px;
  }

  input {
    display: block;
    margin: auto;
    width: 400px;
    max-width: calc(100% - 24px);
    border: 0px;
    height: 50px;
    font-size: 20px;
    border-radius: 42px;
    padding-left: 24px;
    outline: none !important;
    margin-bottom: 16px;
    color: #3c2d11;
    &::placeholder {
      font-weight: 800;
      color: #3c2d11;
    }

    &:-ms-input-placeholder {
      font-weight: 800;
      color: #3c2d11;
    }

    &::-ms-input-placeholder {
      font-weight: 800;
      color: #3c2d11;
    }
  }

  button {
    background: none;
    border: 0px;
    display: block;
    margin: auto;
    cursor: pointer;
    margin-top: 48px;
  }
}

.photos {
  text-align: center;
  background-color: #2f1912;
  img {
    width: 20%;
    display: inline-block;
    max-width: 220px;
    @media screen and (max-width: 900px) {
      width: 33.333%;
      &:nth-child(4) {
        width: 50%;
      }
      &:last-child {
        width: 50%;
      }
    }
    @media screen and (max-width: 600px) {
      width: 50%;
      &:last-child {
        width: 100%;
      }
    }
  }
}

.top {
  background-color: #ffffff;
  background-image: url("~@/assets/jklookbook/fonts/~@/assets/jklookbook/texture.png");
  background-size: cover;
  padding: 60px 10%;
  position: relative;
  @media screen and (max-width: 900px) {
    padding: 60px 0px;
  }
  .container {
    max-width: 1100px;
    display: block;
    margin: auto;
    @media screen and (max-width: 900px) {
      padding: 30px 0px;
      width: 90%;
      display: block;
      margin: auto;
    }
  }
  &__leaf1 {
    position: absolute;
    top: 24px;
    z-index: 100;
    left: 24px;
  }
  &__leaf2 {
    position: absolute;
    top: 75%;
    z-index: 1000;
    left: 45%;
    @media screen and (max-width: 900px) {
      width: 90px;
      top: 315px;
    }
  }
  &__leaf3 {
    position: absolute;
    bottom: 24px;
    z-index: 1000;
    right: 24px;
    @media screen and (max-width: 900px) {
      bottom: 0px;
    }
  }
  &__elipse {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    max-width: 80%;
  }
  &__woman {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 100;
    @media screen and (max-width: 900px) {
      width: 70%;
      display: block;
      margin: auto;
    }
  }
  &__right {
    display: inline-block;
    vertical-align: middle;
    padding-left: 80px;
    width: calc(50% - 80px);
    position: relative;
    z-index: 100;
    @media screen and (max-width: 900px) {
      padding-left: 0px;
      width: 100%;
    }
  }
  &__logo {
    width: 100%;
    @media screen and (max-width: 900px) {
      margin-top: 48px;
    }
  }
  &__jklogo {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  h2 {
    font-weight: 800;
    font-size: 27px;
    text-align: center;
    margin-top: 68px;
    @media screen and (max-width: 900px) {
      margin-top: 16px;
    }
  }
  p {
    text-align: center;
    color: #3c2d11;
    font-size: 29px;
    margin-top: 32px;
    @media screen and (max-width: 900px) {
      margin-top: 16px;
    }
  }
  a {
    display: block;
    text-align: center;
    margin-top: 48px;
  }
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Medium.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Light.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Light.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Medium.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Regular.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Heavy.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Bold.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Heavy.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Black.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Heavy.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Heavy.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Heavy.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("~@/assets/jklookbook/fonts/Texta-Light.woff2") format("woff2"),
    url("~@/assets/jklookbook/fonts/Texta-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Texta";
}
</style>
